//import { Link } from 'react-router-dom'


function Contact() {
    return (
      <div >

<div className="inner-heading-bg-main">
  <div className="wrapper">
    <div className="heading-two-cal-main">
      <div className="heading-two-cal-1">
        <h1>Reach<b> Us</b></h1>
      </div>
      <div className="heading-two-cal-2"><img src={require('../images/contact-icon.png')} alt="iWish" /></div>
    </div>
  </div>
</div>
<div className="home-form-sec-main">
  <div className="wrapper">
    <div className="home-form-sec-flex">
      <div className="home-form-sec-1">
      
        <h5>Do you need help or support? Talk to our experts</h5>
        <h4>+91 86886 21952</h4>
        <p>Or write to us: <b>help@iwish.academy</b></p>
        <div className="our-address-1">
          <h6>Our Address</h6>
          F.No # 202, Plot No # 49, Kalayan Nagar Phase - III, Hyderabad, Telangana - 500018 </div>
      </div>
      <div className="home-form-sec-2">
        <h5>Book A Free Demo</h5>
		
			<div className="home-form-fileds">
				<input type="text" placeholder="First Name" id="firstname" name="firstname" required />
				<input type="text" placeholder="Last Name" id="lastname" name="lastname" required />
				<input type="email" placeholder="Email Id" id="email_id" name="email_id" required />
				<input type="number" placeholder="Phone Number" id="mobile" name="mobile" required />
				<textarea placeholder="Message" id="comment" name="comment"></textarea>
				<input type="hidden" name="action" value="SUBMIT" />
				<input type="Submit" value="SEND MESSAGE" className="btn1"/>
			</div>
		
      </div>
    </div>
  </div>
</div>

      </div>
    );
  }
  
  export default Contact;