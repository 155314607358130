import ChemistryIcon1 from '../images/courses-chemistry-img-5.svg';
import {Link} from 'react-router-dom'
function Chemistry(props) {
    return (
      <div >
      
     
<div className="banner-main">
  <div className="wrapper">
    <div className="courses-inner-sec-main">
      <div className="courses-inner-sec-1"><img src={require('../images/courses-chemistry-img-1.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-2">
        <div className="course-icon-1"><img src={require('../images/chemistry-icon-1.png')} alt="iWish" /></div>
        <div className="course-icon-2"><img src={require('../images/chemistry-icon-2.png')} alt="iWish" /></div>
        <div className="course-icon-3"><img src={require('../images/chemistry-icon-3.png')} alt="iWish" /></div>
        Chemistry ATAR equip students with knowledge, understanding and opportunity to investigate properties and reactions of materials.</div>
    </div>
    <div className="courses-inner-sec-2-main">
      <div className="courses-inner-sec-2-1"> Students recognise hazards and make informed, balanced decisions about chemical use ans sustainable resource management</div>
      <div className="courses-inner-sec-2-2"><img src={require('../images/courses-chemistry-img-2.png')} alt="iWish" /></div>
    </div>
    <div className="courses-inner-sec-3-main">
      <div className="courses-inner-sec-3-2"><img src={require('../images/courses-chemistry-img-3.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-3-1"> Investigations and Laboratory activities develop precision, critical analysis and informed decision making</div>
    </div>
    <div className="quote-m-sec">
      <div className="quote-m-1"><img src={require('../images/vectors-chemistry.png')} alt="iWish" /></div>
      <div className="quote-m-2">Understanding <b>chemistry</b> is relevant to a range of <b>careers</b>, including those in forensic and environmental science , engineering, medicine , dentistry, pharmacy and sport science</div>
    </div>
    <div className="courses-inner-sec-3-main">
      <div className="courses-inner-sec-3-2"><img src={require('../images/courses-chemistry-img-4.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-3-1"> Include Topics in atomic structure and bonding , concept of reversibility of reactions, dynamic nature of equilibrium in chemical systems.</div>
    </div>
    <div className="courses-inner-sec-2-main">
      <div className="courses-inner-sec-2-1">Learn properties of water, acids and bases, use equations to calculate concentrations and volumes of solutions involved in reactions.</div>
      <div className="courses-inner-sec-2-2"><img src={ChemistryIcon1} alt="iwish" /></div>
    </div>
  </div>
</div>

     <div className="book-now-sec-1">
    <Link to="/Contact">Book A Free Demo</Link>
    </div>
      </div>
    );
  }
  
  export default Chemistry;