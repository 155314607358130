import MathsiconMethods1 from '../images/maths-m-icon-1.svg';
import MathsiconMethods2 from '../images/maths-m-icon-2.svg';
import MathsiconMethods3 from '../images/maths-m-icon-3.svg';
//import {Link} from 'react-router-dom'
function MathematicsMethods(props) {
    return (
      <div >
      
<div classNameName="banner-main">
  <div className="wrapper">
    <div className="courses-inner-sec-main">
      <div className="courses-inner-sec-1"><img src={require('../images/courses-sp-maths-img-1.png')} alt="iWish" />
      
         </div>
      <div className="courses-inner-sec-2"> 
        <div className="course-icon-1"><img src={MathsiconMethods1} alt="iWish"/></div>
        <div className="course-icon-2"><img src={MathsiconMethods2} alt="iWish"/></div>
        <div className="course-icon-3"><img src={MathsiconMethods3} alt="iWish"/></div>
        Mathematics Methods ATAR course aims to develop Students</div>
    </div>
    <div className="courses-inner-sec-2-main">
      <div className="courses-inner-sec-2-1"> Understanding of concepts and techniques drawn from Algebra, study of Functions, Calculus, Probability and Statistics</div>
      <div className="courses-inner-sec-2-2"><img src={require('../images/courses-sp-maths-img-2.png')}  alt="iWish" /></div>
    </div>
    <div className="courses-inner-sec-3-main">
      <div className="courses-inner-sec-3-2"><img src={require('../images/courses-sp-maths-img-3.png')}  alt="iWish" /></div>
      <div className="courses-inner-sec-3-1"> The study of statistics develops students’ ability to describe and analyse phenomena that involve uncertainty and variation.</div>
    </div>
     
      <div className="quote-m-sec">
          <div className="quote-m-1"><img src={require('../images/vectors-sp.png')}  alt="iWish" /></div>
          <div className="quote-m-2">
            <b>Mathematics</b> Methods ATAR is a prerequisite for a course you wish to study at <b>university</b>
          </div>
      </div>
      <div className="courses-inner-sec-3-main">
      <div className="courses-inner-sec-3-2"><img src={require('../images/courses-sp-maths-img-4.png')}  alt="iWish" /></div>
      <div className="courses-inner-sec-3-1">Ability to communicate in a concise and systematic manner using appropriate mathematical and statistical language</div>
    </div>
      <div className="courses-inner-sec-2-main">
      <div className="courses-inner-sec-2-1">Ability to choose and use technology appropriately and efficiently</div>
      <div className="courses-inner-sec-2-2"><img src={require('../images/courses-sp-maths-img-5.png')}  alt="iWish" /></div>
    </div>
  </div>
</div>
<div className="book-now-sec-1">
    <a href="/Contact" rel="noreferrer">Book A Free Demo</a>
    </div>
      </div>
    );
  }
  
  export default MathematicsMethods;