import Mathsicon1 from '../images/maths-icon-1.svg';
import Mathsicon2 from '../images/maths-icon-2.svg';
import Mathsicon3 from '../images/maths-icon-3.svg';

function MathematicsSpecialist(props) {
    return (
      <div >
        <div className="banner-main">
  <div className="wrapper">
    <div className="courses-inner-sec-main">
      <div className="courses-inner-sec-1"><img src={require('../images/courses-maths-img-1.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-2"> 
        <div className="course-icon-1"><img src={Mathsicon1} alt="iWish"/></div>
        <div className="course-icon-2"><img src={Mathsicon2} alt="iWish"/></div>
        <div className="course-icon-3"><img src={Mathsicon3} alt="iWish"/></div>
        Mathematics Specialist , ATAR Course, can be selected in conjunction with Mathematics Methods. This Course provides opportunities beyond those presented in Methods Course. </div>
    </div>
    <div className="courses-inner-sec-2-main">
      <div className="courses-inner-sec-2-1"> Include development of rigorous mathematical arguments/proofs and use of mathematical models more extensively </div>
      <div className="courses-inner-sec-2-2"><img src={require('../images/courses-maths-img-2.png')}  alt="iWish" /></div>
    </div>
    <div className="courses-inner-sec-3-main">
      <div className="courses-inner-sec-3-2"><img src={require('../images/courses-maths-img-3.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-3-1"> Includes topics in functions and calculus that build on and deepen the ideas present in Methods courses </div>
    </div>
     
      <div className="quote-m-sec">
          <div className="quote-m-1"><img src={require('../images/vectors.png')}  alt="iWish" /></div>
          <div className="quote-m-2">
            Extend understanding of <b>statistics</b> and introduces topics  <b>Vectors, Complex Numbers and Matrices</b>
          </div>
      </div>
      <div className="courses-inner-sec-3-main">
      <div className="courses-inner-sec-3-2"><img src={require('../images/courses-maths-img-4.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-3-1"> Mathematics Specialist ATAR is prerequisite for course you wish to study at University</div>
    </div>
      <div className="courses-inner-sec-2-main">
      <div className="courses-inner-sec-2-1"> Opt for this course if you have a strong interest in Mathematics, Statistics, all Sciences and associated fields, Economics or Engineering at university</div>
      <div className="courses-inner-sec-2-2"><img src={require('../images/courses-maths-img-5.png')} alt="iWish" /></div>
    </div>
  </div>
</div>
<div className="book-now-sec-1">
    <a href="/Contact" rel="noreferrer">Book A Free Demo</a>
    </div>
      </div>
    );
  }
  
  export default MathematicsSpecialist;