//import { Link } from 'react-router-dom'


function Thankyou() {
    return (
      <div >

<h1>Thank you</h1>

      </div>
    );
  }
  
  export default Thankyou;