//import { Link } from 'react-router-dom'

import LinkedinIcon from '../images/linked-in-1.svg';
function About() {
    return (
      <div >
        

        <div className="banner-main">
  <div className="wrapper">
    <div className="about-inner-sec-1-main">
      <div className="about-inner-sec-1-1">
        <h1>About <b>Us</b></h1>
        <h5>iWish is a digital educational Academy company headquartered at Hyderabad. iWish breaks the shackles of time and place, liberating learning and teaching from the limits of time and location.Be it late at night or early in the morning, students have liberty to reach out for any type of guidance and help. Teachers at iWish are always excited to lend their helping hands !" </h5>
        <div className="about-keywords-sec"> Educate * <b>Enable</b> * <b className='c-1'>Empower </b> </div>
        <h5>iWish ensures that a student gets the entire attention of the dedicated teachers and learns at his/her pace.</h5>
      </div>
      <div className="about-inner-sec-1-2"><img src={require('../images/about-img-1.png')} alt="Deal of the day" /></div>
    </div>
  </div>
</div>
<div className="about-inner-sec-2-main">
  <div className="wrapper">
    <div className="about-inner-sec-2-secondary">
      <div className="about-quote-m-sec">
        <div className="about-quote-m-1"><img src={require('../images/vectors.png')} alt="Deal of the day" /></div>
        <div className="about-quote-m-2">The interaction between the teacher and students is strong and two-way, which is also monitored technologically for further improvement. </div>
      </div>
      <div className="about-quote-m-sec bg-n-1">
        <div className="about-quote-m-1"><img src={require('../images/about-us-icon-2.png')} alt="Deal of the day" /></div>
        <div className="about-quote-m-2">The student is encouraged to ask doubts/questions unlike in a crowded classNameroom. And the student is much comfortable in asking his/her doubts on iwish than anywhere else, as teachers instill questioning skills along with imparting theoretical knowledge.</div>
      </div>
      <div className="about-quote-m-sec bg-n-2">
        <div className="about-quote-m-1"><img src={require('../images/about-us-icon-3.png')} alt="Deal of the day" /></div>
        <div className="about-quote-m-2">Enhanced engagement between student and teacher on iWish guarantees better understanding and recall for the student; unlike in a classNameroom where teacher-student engagement is difficult to measure.</div>
      </div>
    </div>
    <h4>And of course, the student is learning from the comfort of his/her own home – Learning happens best when the student’s mind is relaxed!</h4>
  </div>
</div>
<div className="">
  <div className="wrapper">
    <div className="about-inner-sec-4-main">
      <div className="about-inner-sec-4-2"><img src={require('../images/about-img-2.png')} alt="Deal of the day" /></div>
      <div className="about-inner-sec-4-1">
        <h3>We Believe</h3>
        <p>Every session conducted on iWish is highly personalized, the pedagogy and lesson plans of the teachers are tailor-made to meet the need of students. Moreover, the pace of the lecture is governed by the grasping capabilities of the student.</p>
        <h6>That is the biggest strength of iWish concepts!</h6>
      </div>
    </div>
  </div>
</div>

<div className="clear"></div>

<div className="team-bg-1">
  <div className="wrapper">
    <h2>From the <b>Headquarters</b></h2>
    <div className="team-bg-1-main">
      <div className="team-bg-1-card">
        <div className="team-bg-1-thumb"><img src={require('../images/kaawish.png')} alt="Deal of the day" /></div>
        <h3>Kaawish Shadab</h3>
        <h6>Founder/ CEO</h6>
        <a href="https://in.linkedin.com/in/kaawish-shadab-4301561a4" target="_blank" rel="noreferrer" >
        <img src={LinkedinIcon} alt="iWish"/>
                               </a>
                               </div>
      <div className="team-bg-1-card">
        <div className="team-bg-1-thumb"><img src={require('../images/madhu-kumar-uppaluru.png')} alt="Deal of the day" /></div>
        <h3>Madhu Kumar Uppaluru</h3>
        <h6>Co-Founder / Director</h6>
        <a href="https://www.linkedin.com/in/madhukumar-uppaluru-b0803322/?originalSubdomain=in" target="_blank" rel="noreferrer" >
        <img src={LinkedinIcon} alt="iWish"/>
          </a> </div>
    </div>
    <h2><b>Advisors</b></h2>
    <div className="team-bg-1-main">
      <div className="team-bg-1-card">
        <div className="team-bg-1-thumb"><img src={require('../images/sachin-arora.png')} alt="Deal of the day" /></div>
        <h3>Sachin Arora</h3>
        <h6>Advisor</h6>
        <a href="https://in.linkedin.com/in/sacarora" target="_blank" rel="noreferrer" >
        <img src={LinkedinIcon} alt="iWish"/>
          </a> </div>
      <div className="team-bg-1-card">
        <div className="team-bg-1-thumb"><img src={require('../images/pallav-pandey.png')} alt="Deal of the day" /></div>
        <h3>Pallav Pandey</h3>
        <h6>Advisor</h6>
        <a href="https://in.linkedin.com/in/pallavpandey" target="_blank" rel="noreferrer" >
        <img src={LinkedinIcon} alt="iWish"/>
          </a> </div>
      <div className="team-bg-1-card">
        <div className="team-bg-1-thumb"><img src={require('../images/saurav-jindal.png')} alt="Deal of the day" /></div>
        <h3>Saurav Jindal</h3>
        <h6>Advisor</h6>
        <a href="https://www.linkedin.com/in/saurav-jindal-bb953b5" target="_blank" rel="noreferrer" >
        <img src={LinkedinIcon} alt="iWish"/>
          </a> </div>
    </div>
  </div>
</div>

      </div>
    );
  }
  
  export default About;