import React from "react";
//import $ from 'jquery'
//import {Link} from 'react-router-dom'

class Home extends React.Component {
  render() {
    return(
      <div >
      
     <div className="banner-main">
      
              <div className="wrapper">
                  <div id="carousel-example-generic" className="carousel slide " data-ride="carousel">
                      <div className="carousel-inner" role="listbox">
                          <div className="item active">
                              <img src={require('../images/banner.png')} alt="logo" className="brand-logo" />
                              <div className="carousel-caption anchortag">
                                  <h3>Comprehensive Learning Experience</h3>
                              </div>
                          </div>
                          <div className="item">
                              <img src={require('../images/banner-2.png')} alt="logo" className="brand-logo" />
                              <div className="carousel-caption anchortag">
                                  <h3>Personalized Attention for all Students</h3>
                              </div>
                          </div>
                          <div className="item">

                              <img src={require('../images/banner-3.png')} alt="logo" className="brand-logo" />
                              <div className="carousel-caption anchortag">
                                  <h3>Interactive classNamees for Better Understanding</h3>
                              </div>
                          </div>
                          <div className="item">
                              <img src={require('../images/banner-4.png')} alt="logo" className="brand-logo" />

                              <div className="carousel-caption anchortag">
                                  <h3>Put Your Best Foot Forward for ATAR Course Examination</h3>
                              </div>
                          </div>
                      </div>

                      
                      <a className="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                          <span className="glyphicon glyphicon-chevron-left" aria-hidden="true">&#x2190;</span>
                          <span className="sr-only">Previous</span>
                      </a>
                      <a className="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                          <span className="glyphicon glyphicon-chevron-right" aria-hidden="true">&#x2192;</span>
  <span className="sr-only">Next</span> </a> 
  

                  </div>
              </div>
          </div>


          <div className="section-1">
              <div className="wrapper">
                  <div className="courses-sec-main">
                      <div className="courses-sec-1">
                        <a href="/MathematicsSpecialist" rel="noreferrer"><img src={require('../images/mathematics-specialist.png')} alt="mathematics" />
                          <h3>Mathematics Specialist</h3></a>
                      </div>
                      <div className="courses-sec-1">
                      <a href="/MathematicsMethods" rel="noreferrer"><img src={require('../images/mathematics-methods.png')} alt="mathematics" />
                          <h3>Mathematics Methods</h3></a>
                      </div>
                      <div className="courses-sec-1">
                      <a href="/Physics" rel="noreferrer"><img src={require('../images/physics.png')} alt="physics" />
                          <h3>Physics</h3></a>
                      </div>
                      <div className="courses-sec-1">
                      <a href="/Chemistry" rel="noreferrer"><img src={require('../images/chemistry.png')} alt="chemistry" />
                          <h3>Chemistry</h3></a>
                      </div>
                      <div className="courses-sec-1">
                      <a href="/HumanBiology" rel="noreferrer"><img src={require('../images/human-biology.png')} alt="human-biology." />
                          <h3>Human Biology</h3></a>
                      </div>
                  </div>
              </div>
          </div>

          <div className="section-2">
<div className="wrapper">
  <h2>Why Choose <img src={require('../images/Logo-01-1.png')} alt="iWish"/></h2>
  <div className="section-2-main">
    <div className="section-2-1"><img src={require('../images/why-choose-1.png')} alt="iWish"/></div>
    <div className="section-2-1"><img src={require('../images/why-choose-2.png')} alt="iWish"/></div>
    <div className="section-2-1"><img src={require('../images/why-choose-3.png')} alt="iWish"/></div>
    <div className="section-2-1"><img src={require('../images/why-choose-4.png')} alt="iWish" /></div>
  </div>
</div>
</div>

{/*
<div className="section-3">
<div className="wrapper">
  <h2>Why Choose</h2>
  <p>Personalized education LIVE from the comfort of your home iwish strength is its core focus on personalized LIVE online teaching.</p>
  <div className="section-3-videos">
    <div className="section-3-videos-1">
      <div className="video-card"></div>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    </div>
    <div className="section-3-videos-1">
      <div className="video-card"></div>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    </div>
    <div className="section-3-videos-1">
      <div className="video-card"></div>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
    </div>
  </div>
</div>
</div>
*/}


<div className="section-4">
<h2>What’s new at <img src={require('../images/Logo-01-1.png')} alt="iWish"/></h2>
<div className="wrapper">
  <ul id="whats_new_slider">
    <li>
      <div className="whats-new-at-img"> 
        <div className="deal-img"><a href="/Contact" rel="noreferrer"><img src={require('../images/whats-new-at-img-1.png')} alt="iWish" /></a></div>
        </div>
    </li>
    <li>
      <div className="whats-new-at-img"> 
        <div className="deal-img"><a href="/Contact" rel="noreferrer"><img src={require('../images/whats-new-at-img-2.png')} alt="iWish" /></a></div>
       </div>
    </li>
    <li>
      <div className="whats-new-at-img"> 
        <div className="deal-img"><a href="/Contact" rel="noreferrer"><img src={require('../images/whats-new-at-img-3.png')} alt="iWish" /></a></div>
         </div>
    </li>
    <li>
      <div className="whats-new-at-img"> 
        <div className="deal-img"><a href="/Contact" rel="noreferrer"><img src={require('../images/whats-new-at-img-4.png')} alt="iWish" /></a></div>
       </div>
    </li>
  </ul>
</div>
</div>


<div className="section-5">
  <h2>What Our <b>Students </b>Say</h2>
  <div className="wrapper">
    <ul id="dealoftheday_2">
      <li>
        <div className="our-students-say-card">
          <div className="student-m-flex">
            <div className="student-m-1"><img src={require('../images/karthik.png')} alt="iWish" /></div>
            <div className="student-m-2">Karthik</div>
          </div>
          <p>The teachers on iWish are warm, supportive and friendly. My experience on IWish has been very good. </p>
        </div>
      </li>
      <li>
        <div className="our-students-say-card">
          <div className="student-m-flex">
            <div className="student-m-1"><img src={require('../images/tej.png')} alt="iWish" /></div>
            <div className="student-m-2">Tej</div>
          </div>
          <p>Before I started attending iWish, my grades were average and intermediate. Through the excellent and highly rated teaching at iWish , I was able to increase my depth and understanding in my studies and achieve increasing marks throughout my schooling.</p>
        </div>
      </li>
      <li>
        <div className="our-students-say-card">
          <div className="student-m-flex">
            <div className="student-m-1"><img src={require('../images/karthiga.png')} alt="iWish"/></div>
            <div className="student-m-2">Karthiga</div>
          </div>
          <p>Before I started classNamees at iWish, my grades weren’t the best, and I’d find myself very confused about some of the concepts we learned in className. Now,thanks to the kind and amazing teachers at iWish, my grades have been significantly improved and I have a greater understanding in maths! </p>
        </div>
      </li>
      <li>
        <div className="our-students-say-card">
          <div className="student-m-flex">
            <div className="student-m-1"><img src={require('../images/Priya-Yenugula.png')} alt="iWish" /></div>
            <div className="student-m-2">Priya Yenugula </div>
          </div>
          <p>Personally, iwish has helped me get a better understanding of science and maths concepts. Though I had sufficient knowledge for the year I was in, these tuition classNamees helped me learn new ways to approach questions and expanded my knowledge of topics and course way better... </p>
        </div>
      </li>
    </ul>
  </div>
</div>
<div className="home-form-sec-main">
  <div className="wrapper">
    <div className="home-form-sec-flex">
      <div className="home-form-sec-1">
        <h3>Contact <b>Us</b></h3>
        <h5>Do you need help or support? Talk to our experts</h5>
        <h4>+91 86886 21952</h4>
        <p>Or write to us: <b>help@iwish.academy</b></p>
        <div className="our-address-1">
          <h6>Our Address</h6>
          F.No # 202, Plot No # 49, Kalayan Nagar Phase - III, Hyderabad, Telangana - 500018 </div>
      </div>
      <div className="home-form-sec-2">
        <h5>Book A Free Demo</h5>
        <div className="home-form-fileds">
          <input type="text" placeholder="First Name"/>
          <input type="text" placeholder="Last Name"/>
          <input type="text" placeholder="Email Id"/>
          <input type="text" placeholder="Phone Number"/>
          <textarea placeholder="Message"></textarea>
          <input type="Submit" value="SEND MESSAGE" className="btn1"/>
        </div>
      </div>
    </div>
  </div>
</div>



    </div>
  );
    
  }
}

export default Home
