
//import {Link} from 'react-router-dom'
function Physics(props) {
    return (
      <div >
      
 <div className="banner-main">
  <div className="wrapper">
    <div className="courses-inner-sec-main">
      <div className="courses-inner-sec-1"><img src={require('../images/courses-physics-img-1.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-2"> 
        <div className="course-icon-1"><img src={require('../images/physics-icon-1.png')} alt="iWish" /></div>
        <div className="course-icon-2"><img src={require('../images/physics-icon-2.png')} alt="iWish" /></div>
        <div className="course-icon-3"><img src={require('../images/physics-icon-3.png')} alt="iWish" /></div>
        Physics ATAR, students learn how energy transformation can shape environment from small scale, quantum leaps inside an atoms electron cloud, through the human scale , in vehicles and human body , to large scale , in interactions between galaxies</div>
    </div>
    <div className="courses-inner-sec-2-main">
      <div className="courses-inner-sec-2-1"> Students get opportunities to develop their investigation skills. Use analytical thinking to explain and predit physical phenomena</div>
      <div className="courses-inner-sec-2-2"><img src={require('../images/courses-physics-img-2.png')} alt="iWish" /></div>
    </div>
    <div className="courses-inner-sec-3-main">
      <div className="courses-inner-sec-3-2"><img src={require('../images/courses-physics-img-3.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-3-1"> Students plan and conduct investigations to get answers to range of questions. Collect and interpret data. Perform Indepth observation. Communicate their findings in an appropriate format</div>
    </div>
     
      <div className="quote-m-sec">
          <div className="quote-m-1"><img src={require('../images/vectors-physics.png')} alt="iWish" /></div>
          <div className="quote-m-2">
            <b>Problem Solving</b> and using evidence to make and justify conclusions are transferable <b>skills</b> that are developed in this course
          </div>
      </div>
      <div className="courses-inner-sec-3-main">
      <div className="courses-inner-sec-3-2"><img src={require('../images/courses-physics-img-4.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-3-1"> Include Topics in Thermal, nuclear and electrical physics. Students investigate energy production, transfer and transformation in electrical circuits.</div>
    </div>
      <div className="courses-inner-sec-2-main">
      <div className="courses-inner-sec-2-1">Linear motion and waves. Students describe, explain and predict linear motion. Investigate application of wave models to sound phenomena</div>
      <div className="courses-inner-sec-2-2"><img src={require('../images/courses-physics-img-5.png')} alt="iWish" /></div>
    </div>
  </div>
</div>
<div className="book-now-sec-1">
    <a href="/Contact" rel="noreferrer">Book A Free Demo</a>
    </div>
      </div>
    );
  }
  
  export default Physics;