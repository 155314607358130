import HbIcon1 from '../images/hb-icon-1.svg';
import HbIcon2 from '../images/courses-hb-img-5.svg';
//import {Link} from 'react-router-dom'
function HumanBiology(props) {
    return (
      <div >
<div className="banner-main">
  <div className="wrapper">
    <div className="courses-inner-sec-main">
      <div className="courses-inner-sec-1"><img src={require('../images/courses-hb-img-1.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-2">
        <div className="course-icon-1"><img src={HbIcon1} alt="iWish"/></div>
        <div className="course-icon-2"><img src={require('../images/hb-icon-2.png')} alt="iWish" /></div>
        <div className="course-icon-3"><img src={require('../images/hb-icon-3.png')} alt="iWish" /></div>
        Human Biology course covers wide range of ideas relating to functioning human.</div>
    </div>
    <div className="courses-inner-sec-2-main">
      <div className="courses-inner-sec-2-1"> Student learn about themselves, relating structure to function and how integrated regulation allows individuals to survive in a changing environment.</div>
      <div className="courses-inner-sec-2-2"><img src={require('../images/courses-hb-img-2.png')} alt="iWish" /></div>
    </div>
    <div className="courses-inner-sec-3-main">
      <div className="courses-inner-sec-3-2"><img src={require('../images/courses-hb-img-3.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-3-1"> Student research new discoveries that increasing understanding of causes of dysfunction which can lead to new treatments and preventive measures</div>
    </div>
    <div className="quote-m-sec">
      <div className="quote-m-1"><img src={require('../images/vectors-hb.png')} alt="iWish" /></div>
      <div className="quote-m-2"><b>Reproduction</b> is studied to understand the sources of variation that make each of us <b>unique individuals</b>.</div>
    </div>
    <div className="courses-inner-sec-3-main">
      <div className="courses-inner-sec-3-2"><img src={require('../images/courses-hb-img-4.png')} alt="iWish" /></div>
      <div className="courses-inner-sec-3-1"> Students learn to evaluate risks and benefits to make informed decisions on lifestyle and health related topics such as diet, alternative medical treatments and use of chemical substance.</div>
    </div>
    <div className="courses-inner-sec-2-main">
      <div className="courses-inner-sec-2-1">Include Topics related to Essentials of Human Life, Aging Human Body, Human Health &amp; Environment , Treating Human Body</div>
      <div className="courses-inner-sec-2-2"><img src={HbIcon2} alt="iWish"/></div>
    </div>
  </div>
</div>
<div className="book-now-sec-1">
    <a href="/Contact" rel="noreferrer">Book A Free Demo</a>
    </div>
      </div>
    );
  }
  
  export default HumanBiology;