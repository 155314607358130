
import React from 'react';
import './App.css';
import Home from './Components/Home'
import About from './Components/About'
import MathematicsSpecialist from './Components/MathematicsSpecialist'
import Header from './Components/Header'
import Footer from './Components/Footer'
import Testimonials from './Components/Testimonials'
import { Routes, Route} from 'react-router-dom'
//import { Link, Routes, Route} from 'react-router-dom'
import './Components/styles/iwish.css';
import './Components/styles/banner-animation.css';
import './Components/styles/navigation.css';
import Contact from './Components/Contact'
import MathematicsMethods from './Components/MathematicsMethods'
import HumanBiology from './Components/HumanBiology'
import Physics from './Components/Physics'
import Chemistry from './Components/Chemistry'
import Thankyou from './Components/Thankyou'




function App() {
  return (
    <div className="App">
      
      <Header/>
      {/*<a href='/Home'>Home</a>  <Link to='About'>About</Link>  */}
      <Routes>
      <Route path='/' element={<Home/>}></Route>
      <Route path='/Home' element={<Home/>}></Route>
      <Route path='/About' element={<About/>}></Route>
      <Route path='/MathematicsSpecialist' element={<MathematicsSpecialist/>}></Route>
      <Route path='/Testimonials' element={<Testimonials/>}></Route>
      <Route path='/Contact' element={<Contact/>}></Route>
      <Route path='/MathematicsMethods' element={<MathematicsMethods/>}></Route>
      <Route path='/HumanBiology' element={<HumanBiology/>}></Route>
      <Route path='/Physics' element={<Physics/>}></Route>
      <Route path='/Chemistry' element={<Chemistry/>}></Route>
      <Route path='/Thankyou' element={<Thankyou/>}></Route>
      
     
      
      </Routes>
      <Footer/>
    </div>
  );
}

export default App;
