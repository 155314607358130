import { Link } from 'react-router-dom'
import FacebookLogo from '../images/facebook.svg';
import LinkedinLogo from '../images/linkedin.svg';
import InstagramLogo from '../images/instagram.svg';

function Footer(props) {
    return (
    
  <div >



<div className="footer-main-bg">
  <div className="wrapper">
    <div className="footer-main-flex">
      <div className="footer-main-1">Copyright @ 2022 iWish. All Rights Reserved. | Design and Concept by eparivartan</div>
      <div className="footer-main-2"> 
      <Link to="#"><img src={FacebookLogo}  alt="facebook" /></Link> 
      <Link to="#"><img src={LinkedinLogo} alt="Linkedin" /></Link> 
      <Link to="#"><img src={InstagramLogo} alt="Instagra" /></Link> </div>
    </div>
  </div>
</div>
      </div>
    );
  }
  
  export default Footer;