import { Link } from 'react-router-dom';
import React, {useState} from 'react';
import Logo from '../images/logo-svg.svg';

function Header(props) {

  const [show, setShow]=useState(window.innerWidth > 959)
  
    return (
    
  <div >
   
<div className="word-detail-m-1">
    <div className="v-txt-1"><Link href="tel:0473 179 130">Talk to our experts<br/><b>0473 179 130</b></Link></div>
</div>
  <div className="header">
  <div className="wrapper">
    <div className="header-flx-main">
      <div className="header-flx-1"><img src={Logo} alt="logo"/></div>
      <div className="header-flx-2">

      <button className="nav-button22"  onClick={()=>setShow(!show)}></button>
      {/*<button className="nav-button22" onclick="myFunction()">123</button>*/}

        {
      show?<div>
        
        <nav >
          {/*<ul>
            <li className="active"><a href='/Home' rel="noreferrer" >Home</a></li>
            <li><Link to="About">About Us</Link></li>
            <li><Link>Courses</Link>
              <ul>
                <li><Link to="MathematicsSpecialist">Mathematics Specialist</Link></li>
                <li><Link to="MathematicsMethods">Mathematics Methods</Link></li>
                <li><Link to="Physics">Physics</Link></li>
                <li><Link to="Chemistry">Chemistry</Link></li>
                <li><Link to="HumanBiology">Human Biology</Link></li>
              </ul>
            </li>
            <li><Link to="Testimonials">Testimonials</Link></li>
            <li><Link to="Contact">Contact</Link></li>
        </ul>*/}

        <ul>
            <li className="active"><a href='/Home' rel="noreferrer" >Home</a></li>
            <li><a href="/About">About Us</a></li>
            <li><Link>Courses</Link>
              <ul>
                <li><a href="/MathematicsSpecialist" rel="noreferrer">Mathematics Specialist</a></li>
                <li><a href="/MathematicsMethods" rel="noreferrer">Mathematics Methods</a></li>
                <li><a href="/Physics" rel="noreferrer">Physics</a></li>
                <li><a href="/Chemistry" rel="noreferrer">Chemistry</a></li>
                <li><a href="/HumanBiology" rel="noreferrer">Human Biology</a></li>
              </ul>
            </li>
            <li><a href="/Testimonials" rel="noreferrer">Testimonials</a></li>
            <li><a href="/Contact" rel="noreferrer">Contact</a></li>
          </ul>
        </nav>
        
      </div>:null
    }
        
      </div>
    </div>
  </div>
</div>
      </div>
    );
  }
  
  export default Header;