//import { Link } from 'react-router-dom'


function Testimonials() {
    return (
      <div >
        
        <div className="inner-heading-bg-main">
  <div className="wrapper">
    <div className="heading-two-cal-main">
      <div className="heading-two-cal-1">
        <h1>What Our <b>Students</b> Say</h1>
      </div>
      <div className="heading-two-cal-2"><img src={require('../images/testimonials-icon.png')} alt="iWish" /></div>
    </div>
  </div>
</div>
<div className="home-form-sec-main">
  <div className="wrapper">
    <div className="testimonials-card-main">
      <div className="testimonials-card">
        <p>The teachers on iWish are warm, supportive and friendly. My experience on IWish has been very good.</p>
        <div className="testimonials-m-flex">
          <div className="testimonials-m-1"><img src={require('../images/karthik.png')} alt="iWish" /></div>
          <div className="testimonials-m-2">Karthik</div>
        </div>
      </div>
      <div className="testimonials-card">
        <p>Before I started attending iWish, my grades were average and intermediate. Through the excellent and highly rated teaching at iWish , I was able to increase my depth and understanding in my studies and achieve increasing marks throughout my schooling.</p>
        <div className="testimonials-m-flex">
          <div className="testimonials-m-1"><img src={require('../images/tej.png')} alt="iWish" /></div>
          <div className="testimonials-m-2">Tej</div>
        </div>
      </div>
      <div className="testimonials-card">
        <p>Before I started classNamees at iWish, my grades weren’t the best, and I’d find myself very confused about some of the concepts we learned in className. Now,thanks to the kind and amazing teachers at iWish, my grades have been significantly improved and I have a greater understanding in maths!</p>
        <div className="testimonials-m-flex">
          <div className="testimonials-m-1"><img src={require('../images/karthiga.png')} alt="iWish" /></div>
          <div className="testimonials-m-2">Karthiga</div>
        </div>
      </div>
      <div className="testimonials-card">
        <p>Learning at iWish has developed and improved my studying capabilities with much confidence. The lessons are very thorough, interactive and interesting. The teachers in iWish always ensure my doubts are cleared and that I have fully understood before moving on to the next topic. I am grateful with iWish for all their efforts in connecting me to achieve better learning experience. The coordinator is prompt in responding to my concerns and address any issues that arise. Thank you iWish.</p>
        <div className="testimonials-m-flex">
          <div className="testimonials-m-1"><img src={require('../images/Mahadeva.png')} alt="iWish" /></div>
          <div className="testimonials-m-2">Mahadeva</div>
        </div>
      </div>
      <div className="testimonials-card">
        <p>I have been learning physics and chemistry from iWish and it has been a great experience. The teachers are very thoughtful and their lessons were engaging. The classNamees have been really useful for me and I have seen a lot of improvement in my grades afterwards. The teachers are patient and explain queries in a detailed manner. I would highly recommend iWish to anyone interested in learning maths or science subjects.</p>
        <div className="testimonials-m-flex">
          <div className="testimonials-m-1"><img src={require('../images/Aishu.png')} alt="iWish" /></div>
          <div className="testimonials-m-2">Aishu</div>
        </div>
      </div>
      <div className="testimonials-card">
        <p>Personally, iwish has helped me get a better understanding of science and maths concepts. Though I had sufficient knowledge for the year I was in, these tuition classNamees helped me learn new ways to approach questions and expanded my knowledge of topics and course way better. The teachers I had, catered to my learning style, making it easier for me to get comfortable with not only the teacher, but also the subjects as well.</p>
        <div className="testimonials-m-flex">
          <div className="testimonials-m-1"><img src={require('../images/Priya-Yenugula.png')} alt="iWish" /></div>
          <div className="testimonials-m-2">Priya Yenugula</div>
        </div>
      </div>
      <div className="testimonials-card">
        <p>classNamees at iWish has really improved my understanding on tje mathematical concepts learnt at school, I can understand the things we learn with greater ease</p>
        <div className="testimonials-m-flex">
          <div className="testimonials-m-1"><img src={require('../images/Father-of-Vishnu.png')} alt="iWish" /></div>
          <div className="testimonials-m-2">Father of Vishnu</div>
        </div>
      </div>
      <div className="testimonials-card">
        <p>One of the best comprehensive learning platform. </p>
        <p>iWish is a great initiative taken by the founders and the educators to share knowledge and help the students who are preparing for Atar exam.</p>
        <div className="testimonials-m-flex">
          <div className="testimonials-m-1"><img src={require('../images/Mother-of-Naimish.png')} alt="iWish" /></div>
          <div className="testimonials-m-2">Mother of Naimish</div>
        </div>
      </div>
      <div className="testimonials-card">
        <p>His marks have improved so much from last year. Even his teachers at school were surprised seeing the radical shift. Thank you iWish</p>
        <div className="testimonials-m-flex">
          <div class="testimonials-m-1"><img src={require('../images/Father-of-Himish.png')} alt="iWish" /></div>
          <div class="testimonials-m-2">Father of Himish</div>
        </div>
      </div>
    </div>
  </div>
</div>
        

      </div>
    );
  }
  
  export default Testimonials;